import React from 'react'

import Container from 'components/Container'
import Title from 'components/Title'
import Button from 'components/Button'
import ReinsuranceItem from 'components/ReinsuranceItem'

const Reinsurance = ({
  title,
  items,
  ctaLabel,
  ctaLink
}) => {
  return (
    <Container size='small' className='Reinsurance' tag='section'>
      <Title tag='h2' className='Reinsurance-title'>{ title }</Title>
      <ul className='Reinsurance-items'>
        {items.map((item, i) => (
          <ReinsuranceItem
            key={ `reinsurance-${i}` }
            icon={ item.illustration.url }
            label={ item.label }
          />
        ))}
      </ul>
      <Button to={ ctaLink } className='Reinsurance-button'>{ ctaLabel }</Button>
    </Container>
  )
}

export default Reinsurance
