import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { graphql } from 'gatsby'
import { useAtom } from 'jotai'
import { useIntl } from 'react-intl'
import Layout from 'components/Layout'
import Hero from 'components/Hero'
import Reinsurance from 'components/Reinsurance'
import Warranties from 'components/Warranties'
import Customization from 'components/Customization'
import FAQ from 'components/FAQ'
import MoreOffers from 'components/MoreOffers'
import { alternateLanguagesState } from '../atoms/languageSwitcher'
import { metaTitle } from '../utils/page-meta-utils'

const OfferTemplate = ({ data }) => {
  if (!data) return null
  const document = data.prismicOffer

  const intl = useIntl()
  const [alternateLanguages, setAlternateLanguages] = useAtom(alternateLanguagesState)

  useEffect(() => {
    setAlternateLanguages(document.alternate_languages)
  }, [])

  return (
    <Layout pageName='HomePage'>
      <Helmet>
        <title>{ document.data.meta_title || metaTitle(document.data.title.text, intl.formatMessage({ id: 'offers.meta_title' })) }</title>
        <meta name='description' content={ document.data.meta_description || '' } />
      </Helmet>
      <Hero
        fullHeight
        align='left'
        tag={ document.data.offer_type }
        tagColor={ document.data.offer_type === 'Particulier' ? '#F37020' : '#325E9B' }
        tagBackgroundColor={ document.data.offer_type === 'Particulier' ? '#FFEFD8' : '#E5EBF4' }
        title={ document.data.title.text }
        subtitle={ document.data.introduction.text }
        ctaLabel={ document.data.cta_label }
        ctaTo={ document.data.cta_link }
        background={ document.data.background.url }
        backgroundMobile={ document.data.background_mobile.url }
      />
      {document.data.display_reinsurance && (
        <Reinsurance
          title={ document.data.reinsurance_title.text }
          items={ document.data.reinsurance_items }
          ctaLabel={ document.data.reinsurance_cta_label }
          ctaLink={ document.data.reinsurance_cta_link }
        />
      )}
      {document.data.display_warranties && (
        <Warranties
          title={ document.data.warranties_title }
          items={ document.data.warranties }
        />
      )}
      {document.data.display_customization && (
        <Customization
          title={ document.data.customization_title.text }
          introduction={ document.data.customization_introduction.text }
          items={ document.data.customization_items }
        />
      )}
      {document.data.display_faq && (
        <FAQ
          title={ document.data.faq_title }
          items={ document.data.faq_items }
        />
      )}
      {document.data.display_more_offers && (
        <MoreOffers
          title={ document.data.more_offers_title }
          items={ document.data.more_offers }
        />
      )}
    </Layout>
  )
}

export const query = graphql`
  query OfferQuery($uid: String, $lang: String) {
    prismicOffer(uid: { eq: $uid }, lang: { eq: $lang }) {
      lang
      alternate_languages {
        id
        type
        lang
        uid
      }
      data {
        meta_title
        meta_description
        title {
          text
        }
        introduction {
          text
        }
        background {
          url
        }
        background_mobile {
          url
        }
        offer_type
        cta_label
        cta_link
        display_faq
        faq_title
        faq_items {
          question {
            text
          }
          answer {
            text
          }
        }
        display_warranties
        warranties_title
        warranties {
          title1 {
            text
          }
          description {
            text
          }
          lottie {
            url
          }
          cta_label {
            text
          }
          popin_content {
            html
          }
        }
        display_more_offers
        more_offers_title
        more_offers {
          offer {
            document {
              ... on PrismicOffer {
                uid
                lang
                type
                data {
                  title {
                    text
                  }
                  offer_type
                  thumbnail {
                    url
                  }
                }
              }
            }
          }
          short_description {
            text
          }
        }
        display_reinsurance
        reinsurance_title {
          text
        }
        reinsurance_items {
          illustration {
            url
          }
          label
        }
        reinsurance_cta_label
        reinsurance_cta_link
        display_customization
        customization_title {
          text
        }
        customization_introduction {
          text
        }
        customization_items {
          image {
            url
          }
          label
          text
        }
      }
    }
  }
  `

export default OfferTemplate
