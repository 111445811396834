import React, { useEffect, useRef } from 'react'
import gsap from 'gsap'
import SimpleBar from 'simplebar-react'
import 'simplebar/dist/simplebar.min.css'

import Paragraph from 'components/Paragraph'

import innerHTML from '../utils/innerHTML'

const WarrantiesItemModal = ({
  active = true,
  handleCloseModal = () => {},
  title = '',
  content = ''
}) => {
  const modalRef = useRef()
  const backgroundRef = useRef()
  const containerRef = useRef()
  const scrollableRef = useRef()

  useEffect(() => {
    if (active) {
      gsap.set(modalRef.current, { display: 'flex' })

      gsap.to(backgroundRef.current, {
        autoAlpha: 1,
        duration: 0.5
      })

      gsap.to(containerRef.current, {
        autoAlpha: 1,
        duration: 0.3,
        delay: 0.2
      })
    } else {
      gsap.to(backgroundRef.current, {
        autoAlpha: 0,
        duration: 0.5,
        delay: 0.2,
        onComplete: () => {
          scrollableRef.current.scrollTop = 0
          gsap.set(modalRef.current, { display: 'none' })
        }
      })

      gsap.to(containerRef.current, {
        autoAlpha: 0,
        duration: 0.3
      })
    }
  }, [active])

  return (
    <div className='WarrantiesItemModal' ref={ modalRef }>
      <div className='WarrantiesItemModal-background' ref={ backgroundRef } onClick={ handleCloseModal } />
      <div className='WarrantiesItemModal-container' ref={ containerRef }>
        <div className='WarrantiesItemModal-close' onClick={ handleCloseModal }>
          <i className='icon-cross' />
        </div>
        <div className='WarrantiesItemModal-content'>
          <SimpleBar
            className='WarrantiesItemModal-scrollable'
            scrollableNodeProps={ { ref: scrollableRef } }
          >
            <Paragraph className='WarrantiesItemModal-title' style='bigInter' color='darkBlue'>{ title }</Paragraph>
            <div className='WarrantiesItemModal-text Wysiwyg' { ...innerHTML(content) } />
          </SimpleBar>
        </div>
      </div>
    </div>
  )
}

export default WarrantiesItemModal
