import React, { useState } from 'react'
import Lottie from 'react-lottie'

import Paragraph from 'components/Paragraph'
import Button from 'components/Button'
import WarrantiesItemModal from 'components/WarrantiesItemModal'

const WarrantiesItem = ({
  title,
  popinContent,
  active,
  description,
  ctaLabel,
  lottie
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  return (
    <li className={ `WarrantiesItem ${active && 'WarrantiesItem--active'}` }>
      <div className='WarrantiesItem-text'>
        <Paragraph style='bigInter' color='darkBlue' className='WarrantiesItem-title'>{ title }</Paragraph>
        <Paragraph style='small' className='WarrantiesItem-description'>{ description }</Paragraph>
        {!!popinContent && (
          <Button onClick={ () => setIsModalOpen(true) } style='transparentDarkBlue'>{ ctaLabel }</Button>
        )}
      </div>
      <div className='WarrantiesItem-image'>
        {lottie && (
          <Lottie
            options={ {
              loop: true,
              autoplay: true,
              path: lottie
            } }
            height='auto'
            width='100%'
          />
        )}
      </div>
      {!!popinContent && (
        <WarrantiesItemModal
          active={ isModalOpen }
          handleCloseModal={ () => setIsModalOpen(false) }
          title={ title }
          content={ popinContent }
        />
      )}
    </li>
  )
}

export default WarrantiesItem
