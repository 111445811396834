import React, { useEffect, useState } from 'react'
import Select, { components } from 'react-select'
import SimpleBar from 'simplebar-react'
import 'simplebar/dist/simplebar.min.css'

import Container from 'components/Container'
import Title from 'components/Title'
import WarrantiesTabsItem from 'components/WarrantiesTabsItem'
import WarrantiesItem from 'components/WarrantiesItem'

const customSelectStyles = {
  indicatorSeparator: (provided) => ({
    ...provided,
    display: 'none'
  }),
  container: (provided) => ({
    ...provided,
    margin: '3rem 0'
  }),
  control: (provided) => ({
    ...provided,
    border: 'none',
    boxShadow: 'none',
    height: '7rem',
    borderRadius: '3.5rem',
    backgroundColor: '#D2E3FA',
    '&:hover': {
      borderColor: 'rgba(0, 43, 104, 0.2)'
    },
    padding: '0 2rem'
  }),
  singleValue: (provided) => ({
    ...provided,
    fontFamily: 'inherit',
    color: '#000000',
    fontWeight: 'bold',
    fontSize: '1.5rem',
    lineHeight: '130%',
    textOverflow: 'unset',
    whiteSpace: 'initial'
  }),
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? '#002B68' : '#000000',
    backgroundColor: state.isSelected ? 'rgba(210, 227, 250, 0.3)' : '#FFFFFF',
    fontWeight: 'bold'
  })
}

const Warranties = ({ title, items }) => {
  const options = items.map((warranty, i) => ({
    label: warranty.title1.text,
    value: i
  }))

  const [activeItem, setActiveItem] = useState(0)
  const [selectedOption, setSelectedOption] = useState(options[0])
  const [isSelectOpen, setIsSelectOpen] = useState(false)
  const [contentHeight, setContentHeight] = useState('auto')

  const handleSelectChange = (value) => {
    setSelectedOption(value)
    setActiveItem(value.value)
  }

  const getItemsMaxHeight = () => {
    return Math.max.apply(Math, Array.from(document.querySelectorAll('.WarrantiesItem')).map((el) => el.clientHeight))
  }

  useEffect(() => {
    setContentHeight(getItemsMaxHeight())

    window.addEventListener('resize', () => {
      setContentHeight(getItemsMaxHeight())
    })
  }, [])

  const renderScrollbar = ({ children }) => {
    return (
      <SimpleBar
        className='Warranties-scrollable'
        autoHide={ false }
      >
        {children}
      </SimpleBar>
    )
  }

  return (
    <section className='Warranties'>
      <Container size='medium' className='Warranties-content'>
        <Title tag='h2' className='Warranties-title' textAlign='center'>{ title }</Title>
        <ul className='Warranties-tabs'>
          {items.map((warranty, i) => (
            <WarrantiesTabsItem
              title={ warranty.title1.text }
              active={ activeItem === i }
              onClick={ () => setActiveItem(i) }
              key={ `warranty-tab-${i}` }
            />
          ))}
        </ul>
        <Select
          className={ `Warranties-dropdown ${isSelectOpen ? 'Warranties-dropdown--open' : ''}` }
          options={ options }
          isSearchable={ false }
          onChange={ handleSelectChange }
          onMenuOpen={ () => { setIsSelectOpen(true) } }
          onMenuClose={ () => { setIsSelectOpen(false) } }
          value={ selectedOption }
          styles={ customSelectStyles }
          components={ { MenuList: renderScrollbar } }
        />
        <ul className='Warranties-content' style={ { height: contentHeight } }>
          {items.map((warranty, i) => (
            <WarrantiesItem
              active={ activeItem === i }
              title={ warranty.title1.text }
              popinContent={ warranty.popin_content ? warranty.popin_content.html : '' }
              description={ warranty.description.text }
              ctaLabel={ warranty.cta_label.text }
              lottie={ warranty.lottie.url }
              key={ `warranty-item-${i}` }
            />
          ))}
        </ul>
      </Container>
    </section>
  )
}

export default Warranties
