import React from 'react'
import Container from 'components/Container'
import Title from 'components/Title'
import MoreOffersItem from 'components/MoreOffersItem'
import { linkResolver } from '../utils/linkResolver'

const MoreOffers = ({ title, items }) => {
  const slicedItems = items.slice(0, 3)

  if (!slicedItems.length) {
    return null
  }

  return (
    <Container size='medium' className='MoreOffers' tag='section'>
      <Title tag='h2' className='MoreOffers-title'>{ title }</Title>
      <div className='MoreOffers-items'>
        {slicedItems.map((item, i) => {
          const data = item.offer.document.data

          return (
            <MoreOffersItem
              key={ `more-offers-${i}` }
              to={ linkResolver(item.offer.document) }
              image={ data.thumbnail.url }
              tag={ data.offer_type }
              tagColor={ data.offer_type === 'Particulier' ? '#F37020' : '#325E9B' }
              tagBackgroundColor={ data.offer_type === 'Particulier' ? '#FFEFD8' : '#E5EBF4' }
              title={ data.title.text }
              description={ item.short_description.text }
            />
          )
        })}
      </div>
    </Container>
  )
}

export default MoreOffers
