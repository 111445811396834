import React from 'react'
import Lottie from 'react-lottie'

import Title from 'components/Title'

const ReinsuranceItem = ({
  icon,
  label
}) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    path: icon
  }

  return (
    <li className='ReinsuranceItem'>
      <div className='ReinsuranceItem-icon'>
        <Lottie
          options={ defaultOptions }
          height='auto'
          width='100%'
        />
      </div>
      <Title tag='h4' className='ReinsuranceItem-label'>{ label }</Title>
    </li>
  )
}

export default ReinsuranceItem
