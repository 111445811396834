import React from 'react'

import Paragraph from 'components/Paragraph'

const CustomizationItem = ({
  icon,
  title,
  description
}) => {
  return (
    <li className='CustomizationItem'>
      <img src={ icon } className='CustomizationItem-icon' />
      <Paragraph style='small' className='CustomizationItem-title'>{ title }</Paragraph>
      <Paragraph style='verySmall' className='CustomizationItem-text'>{ description }</Paragraph>
    </li>
  )
}

export default CustomizationItem
