import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import gsap from 'gsap'

import Paragraph from 'components/Paragraph'

const Reinsurance = ({
  question = '',
  answer = ''
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const answerRef = useRef()

  useEffect(() => {
    gsap.to(answerRef.current, {
      duration: 0.3,
      height: isOpen ? 'auto' : 0
    })
  }, [isOpen])

  return (
    <li className='FAQItem'>
      <div className='FAQItem-question' onClick={ () => setIsOpen(!isOpen) }>
        <Paragraph style='small'>{ question }</Paragraph>
        <i className={ `FAQItem-icon ${isOpen ? 'icon-minus' : 'icon-plus'}` } />
      </div>
      <div className='FAQItem-answer' ref={ answerRef }>
        <Paragraph style='faq'>{ answer }</Paragraph>
      </div>
    </li>
  )
}

Reinsurance.propTypes = {
  question: PropTypes.string,
  answer: PropTypes.string
}

export default Reinsurance
