import React from 'react'

import Title from 'components/Title'
import Link from 'components/Link'
import Paragraph from 'components/Paragraph'
import Tag from 'components/Tag'

const MoreOffersItem = ({
  to,
  image,
  tag,
  tagColor,
  tagBackgroundColor,
  title,
  description
}) => {
  return (
    <Link to={ to } className='MoreOffersItem'>
      <div className='MoreOffersItem-image'>
        <img src={ image } />
      </div>
      <Tag color={ tagColor } backgroundColor={ tagBackgroundColor } label={ tag } className='MoreOffersItem-tag' />
      <Title tag='h3' className='MoreOffersItem-title'>{ title }</Title>
      <Paragraph style='small' className='MoreOffersItem-description'>{ description }</Paragraph>
    </Link>
  )
}

export default MoreOffersItem
