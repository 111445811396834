import React from 'react'

import Container from 'components/Container'
import Title from 'components/Title'
import Paragraph from 'components/Paragraph'
import CustomizationItem from 'components/CustomizationItem'

const Customization = ({
  title,
  introduction,
  items
}) => {
  return (
    <Container size='small' className='Customization' tag='section'>
      <Title tag='h2' className='Customization-title' textAlign='center'>{ title }</Title>
      <Paragraph className='Customization-intro' textAlign='center'>{ introduction }</Paragraph>
      <ul className='Customization-items'>
        {items.map((item, i) => (
          <CustomizationItem
            key={ `customization-${i}` }
            icon={ item.image.url }
            title={ item.label }
            description={ item.text }
          />
        ))}
      </ul>
    </Container>
  )
}

export default Customization
