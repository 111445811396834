import React from 'react'

import Paragraph from 'components/Paragraph'

const WarrantiesTabsItem = ({
  title,
  active,
  onClick
}) => {
  return (
    <li className={ `WarrantiesTabsItem ${active && 'WarrantiesTabsItem--active'}` } onClick={ onClick }>
      <Paragraph style={ active ? 'verySmall' : 'verySmallBold' }>{ title }</Paragraph>
    </li>
  )
}

export default WarrantiesTabsItem
