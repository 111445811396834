import React from 'react'

import Container from 'components/Container'
import Title from 'components/Title'
import FAQItem from 'components/FAQItem'

import mountain1 from 'assets/images/mountain-1.svg'
import mountain2 from 'assets/images/mountain-2.svg'
import trees from 'assets/images/trees.svg'

const FAQ = ({ title, items }) => {
  return (
    <section className='FAQ'>
      <Container size='small' className='FAQ-content'>
        <Title tag='h2' textAlign='center' className='FAQ-title'>{ title }</Title>
        <ul className='FAQ-items'>
          {items.map((item, i) => (
            <FAQItem
              key={ `item-${i}` }
              question={ item.question.text }
              answer={ item.answer.text }
            />
          ))}
        </ul>
      </Container>
      <img className='FAQ-trees' src={ trees } />
      <img className='FAQ-mountain1' src={ mountain1 } />
      <img className='FAQ-mountain2' src={ mountain2 } />
    </section>
  )
}

export default FAQ
